import React from "react"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import L from "leaflet"

export const markerIcon =
  typeof window !== "undefined"
    ? new L.Icon({
        iconUrl: require("../images/logo.svg"),
        iconRetinaUrl: require("../images/logo.svg"),
        iconAnchor: [25, 25],
        popupAnchor: [0, -25],
        iconSize: [50, 50],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
      })
    : false

const ContactMap = ({ lat, lng, children }) => {
  return typeof window !== "undefined" ? (
    <Map center={[lat, lng]} zoom={`14`}>
      {console.log(markerIcon)}
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <Marker position={[lat, lng]} icon={markerIcon}>
        <Popup>{children}</Popup>
      </Marker>
    </Map>
  ) : null
}

export default ContactMap
