import React, { useState } from "react"
import SEO from "../components/seo"
import PageHeader from "../components/page_header"
import Layout from "../components/layout"
import ContactMap from "../components/ContactMap"
import { graphql } from "gatsby"
import encode from "../helpers/_encode"

export const query = graphql`
  query($id: String!) {
    contact: wordpressPage(id: { eq: $id }) {
      id
      slug
      title
      acf {
        description
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
  }
`

const Contact = ({ data }) => {
  const contact = data.contact
  const meta = data.contact.yoast_meta
  const initialFormContent = {
    lastname: "",
    firstname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  }
  const [formValues, setFormValues] = useState(initialFormContent)
  const [formStatus, setFormStatus] = useState("initial")

  const handleInputChange = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    setFormValues({ ...formValues, [name]: value })
  }

  const handleFormSubmit = event => {
    event.preventDefault()
    setFormStatus("sending")

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formValues }),
    })
      .then(() => {
        setFormStatus("send")
        setFormValues(initialFormContent)
      })
      .catch(error => {
        console.error(error)
        setFormStatus("error")
      })
  }

  return (
    <Layout>
      <SEO
        title={meta.yoast_wpseo_title}
        description={meta.yoast_wpseo_metadesc}
      />
      <PageHeader title={contact.title} image={contact.acf.banner_image} />
      <main className="content__container contact__wrapper">
        <section className="contact__shops">
          <div className="contact__shops-wrapper">
            <div className="contact__shops-item">
              <h2>Paul Roth et Fils Obernai</h2>
              {typeof window !== undefined ? (
                <ContactMap lat={`48.460659`} lng={`7.490207`}>
                  <p>
                    7 Rue du Général Gouraud
                    <br />
                    67210 Obernai
                    <br />
                    <a
                      href="http://maps.google.com/maps?f=d&daddr=Roth+et+Fils+Ets,+7+Rue+du+Général+Gouraud,+67210+Obernai"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Naviguer
                    </a>
                  </p>
                </ContactMap>
              ) : null}
              <p>
                7 Rue du Général Gouraud
                <br />
                67210 Obernai
                <br />
                <a
                  href="http://maps.google.com/maps?f=d&daddr=Roth+et+Fils+Ets,+7+Rue+du+Général+Gouraud,+67210+Obernai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Naviguer
                </a>
              </p>
              <p>
                Tel: <a href="tel:+33388956144">03 88 95 61 44</a>
              </p>
              <p>Fax: 03 88 95 03 89</p>
            </div>
            <div className="contact__shops-item">
              <h2>Paul Roth et Fils Illzach</h2>
              {typeof window !== undefined ? (
                <ContactMap lat={`47.764119`} lng={`7.368841`}>
                  <p>
                    8 Avenue d'Italie
                    <br />
                    68110 Illzach
                  </p>
                  <a
                    href="http://maps.google.com/maps?f=d&daddr=FRANCE+MATÉRIAUX+ROTH+PAUL+ET+FILS,+8+Avenue+d'Italie,+68110+Illzach"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Naviguer
                  </a>
                </ContactMap>
              ) : null}
              <p>
                8 Avenue d'Italie
                <br />
                68110 Illzach
                <br />
                <a
                  href="http://maps.google.com/maps?f=d&daddr=FRANCE+MATÉRIAUX+ROTH+PAUL+ET+FILS,+8+Avenue+d'Italie,+68110+Illzach"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Naviguer
                </a>
              </p>
              <p>
                Tel: <a href="tel:+33389310801">03 89 31 08 01</a>
              </p>
            </div>
          </div>
        </section>
        <section className="contact__shops">
          <h2>Envoyez nous un message</h2>
          <div className="contact__shops-form">
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={target => handleFormSubmit(target)}
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="form-group column-6">
                <input
                  type="text"
                  id="input_lastname"
                  placeholder="Votre nom"
                  name="lastname"
                  onChange={target => handleInputChange(target)}
                  value={formValues.lastname}
                  required
                />
                <label htmlFor="input_lastname">Votre nom</label>
              </div>
              <div className="form-group column-6">
                <input
                  type="text"
                  id="input_firstname"
                  placeholder="Votre prénom"
                  name="firstname"
                  onChange={target => handleInputChange(target)}
                  value={formValues.firstname}
                  required
                />
                <label htmlFor="input_firstname">Votre prénom</label>
              </div>
              <div className="form-group column-6">
                <input
                  type="text"
                  id="input_mail"
                  placeholder="Votre adresse mail"
                  name="email"
                  value={formValues.email}
                  onChange={target => handleInputChange(target)}
                  required
                />
                <label htmlFor="input_mail">Votre adresse mail</label>
              </div>
              <div className="form-group column-6">
                <input
                  type="text"
                  id="input_phone"
                  placeholder="Votre numéro de téléphone"
                  name="phone"
                  value={formValues.phone}
                  onChange={target => handleInputChange(target)}
                />
                <label htmlFor="input_phone">Votre numéro de téléphone</label>
              </div>
              <div className="form-group column-12">
                <input
                  type="text"
                  id="input_title"
                  placeholder="L'objet de votre demande"
                  name="subject"
                  value={formValues.subject}
                  onChange={target => handleInputChange(target)}
                />
                <label htmlFor="input_title">L'objet de votre demande</label>
              </div>
              <div className="form-group column-12">
                <textarea
                  id="input_message"
                  placeholder="votre message"
                  name="message"
                  value={formValues.message}
                  onChange={target => handleInputChange(target)}
                  required
                />
                <label htmlFor="input_message">Votre message</label>
              </div>
              {formStatus === "send" && (
                <div className="form-group column-12">
                  <p>
                    Merci pour votre message. Nous vous répondrons dans le plus
                    bref délais
                  </p>
                </div>
              )}
              {formStatus === "error" && (
                <div className="form-group column-12">
                  <p>
                    Un problème s'est produit. Merci de réessayer
                    ultérieurement.
                  </p>
                </div>
              )}

              {(formStatus === "initial" ||
                formStatus === "send" ||
                formStatus === "error") && (
                <button type="submit" className="btn btn-primary">
                  <span>Envoyer</span>
                </button>
              )}
              {formStatus === "sending" && (
                <button type="submit" className="btn btn-primary" disabled>
                  <span>sending</span>
                </button>
              )}
            </form>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Contact
